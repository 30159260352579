// Search
function searchState(state) {
    if (state == 'mediaDesktop') {
        $('.search-box__wrapper').dropDownMenu({
            disabled: true,
            classBtn: 'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
    }
    if (state == 'mediaTablet') {
        $('.search-box__wrapper').dropDownMenu({
            disabled: true,
            classBtn: 'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
    }
    else if ((state == 'mediaSmartphone')) {
        //$('#searchform .dropDownMenu').dropDownMenu(reset);
        $('.search-box__wrapper').dropDownMenu({
            disabled: false,
            classBtn: 'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
    }
}
