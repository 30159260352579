
function initStickyMenu(state) {
    var stickyMenuClass = 'sticky-menu';

    var $win = $(window),
        $body = $('body'),
        $menu = $('#menu__wrap');

    if (state == 'mediaDesktop') {
        if ($('#menu__wrap').length > 0) {
            var headerTop = $menu.offset().top,
                isSticky = false;

            check();
            $win.on('scroll', check);

            function setMargin() {
                if($('body').hasClass("home")) {
                   // var height = $(".menu-main-1__wrapper").outerHeight();
                    $(".main").css("margin-top", 58);
                }
                else {
                    $(".top-of-content").css('margin-top', 65);
                }
            }

            function check() {
                if (!isSticky && $win.scrollTop() > headerTop) {
                    isSticky = true;
                    $body.addClass(stickyMenuClass);
                    setMargin();

                }
                else if (isSticky && $win.scrollTop() <= headerTop) {
                    isSticky = false;
                    $(".main").css("margin-top", 0);
                    $(".top-of-content").css('margin-top', 0);
                    $body.removeClass(stickyMenuClass);

                }
            }

        };
    }
    else if ((state == 'mediaSmartphone') || (state == 'mediaTablet')) {
        $body.removeClass(stickyMenuClass);
    }
}
