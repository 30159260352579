/* Common variables */

var hostName = location.hostname;
var rootPath = '/';

if (hostName === 'integration.stratis.fr') {
    rootPath = "";
}

//var typo3Path = '/typo3conf/ext/stratis_site/Resources/Public/'; // Remove because doesn't work in http://integration.stratis.fr/typo3-8.7/page-content.html
var cmsPath = rootPath+'typo3conf/ext/stratis_site/Resources/Public/';

var pathImg = cmsPath + "Images/",
    pathImgFancybox = cmsPath + "Images/fancybox/";
