function removeNameAttribute(field) {
    if ($('[name="' + field + '"]').val() === '0' || $('[name="' + field + '"]').val() === '') {
        $('[name="' + field + '"]').removeAttr('name');
        if (field === 'tx_news_pi1[search][subject]') {
            $('[name="tx_news_pi1[__referrer][@request]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@extension]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@vendor]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@controller]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@action]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][arguments]"]').removeAttr('name');
            $('[name="tx_news_pi1[__trustedProperties]"]').removeAttr('name');
        }
        if (field === 'tx_stratismap_location[keyword]') {
            $('[name="tx_stratismap_location[__referrer][@extension]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@vendor]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@controller]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@action]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][arguments]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@request]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__trustedProperties]"]').removeAttr('name');
        }
    }
}
function showSubcat() {
    var selectedParentCategory = $('#categories').find('option:selected').val();
    if (selectedParentCategory !== '' && selectedParentCategory !== '0') {
        $('.subcat').addClass('hidden').find('select').attr('disabled', true);
        var selectedSubCategoryWrapper = $('[data-id="' + selectedParentCategory + '"]');
        if (selectedSubCategoryWrapper.length > 0) {
            $('.default-subcat').addClass('hidden').find('select').attr('disabled', true);
            selectedSubCategoryWrapper.removeClass('hidden').find('select').removeAttr('disabled');
        } else {
            $('.default-subcat').removeClass('hidden');
        }
    }
}
function catmenu() {
    if ($('.filters').length > 0) {
        // gestion du menu des archives pour news
        if ($('.filters #tx_news_month').length > 0) {
            if ($('#tx_news_month option:selected').length == 1) {
                catmenuYear = $('#tx_news_month option[selected="selected"]').parent('optgroup').attr('label');
                $('input#tx_news_year').val(catmenuYear);
            }
            $('#tx_news_month').change(function () {
                catmenuYear = $(this).find('option:selected').parent('optgroup').attr('label');
                if (catmenuYear == 'undefined') {
                    $('input#tx_news_year').val('');
                } else {
                    $('input#tx_news_year').val(catmenuYear);
                }
            });
        }

        // cat/sous-cat
        if ($('.subcats').length > 0) {
            showSubcat();
            $('#categories').on('change', function () {
                showSubcat();
            });
        }

        // action à la validation du formulaire
        $('.filters form').on('submit', function () {
            if ($('.subcat').length > 0) {
                if ($('.subcat:visible').length > 0 && $('.subcat select').val() !== '0' && $('.subcat select').val() !== '') {
                    $('.hiddenCat').val($('.subcat select').val());
                }
                else if ($('#categories').val() !== '0' && $('#categories').val() !== '') {
                    $('.hiddenCat').val($('#categories').val());
                } else {
                    $('.hiddenCat').val('');
                }
            }

            // datepicker
            if ($('#datepicker-hidden').length > 0) {
                if ($('#datepicker-hidden').val().length > 0) {
                    var tab = $('#datepicker-hidden').val().split('-');
                    $('#dated').val(tab[2]);
                    $('#datem').val(tab[1]);
                    $('#datey').val(tab[0]);
                }
            }

            // supprime les attributs name si les champs sont vides, pour éviter d'ajouter des paramètres vides dans l'url
            removeNameAttribute('tx_news_pi1[overwriteDemand][period]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][related]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][categories]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][day]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][month]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][year]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][datecomplete]');
            removeNameAttribute('tx_news_pi1[search][subject]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][tags]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][relatedLinks]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][related]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][relatedLocations]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][relatedAreas]');
            removeNameAttribute('tx_simplepoll_polllisting[category]');
            removeNameAttribute('tx_simplepoll_polllisting[status]');
            removeNameAttribute('tx_stratismap_location[search][keywords]');
            removeNameAttribute('tx_stratismap_location[search][categories][]');
        });
    }
}
