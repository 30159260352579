// carouselCollection
function carouselImageState() {
    var carouselWrap = $('.images-gallery');
    var carouselClone = $('.images-gallery-clone');
    var carousel = $('.images-gallery__listitems', carouselClone);
    var carouselItems = $('.images-gallery__article', carouselWrap).length;
    var tablet = window.matchMedia("(max-width: 1279px)");
    var smartphone = window.matchMedia("(max-width: 767px)");

    if (carouselWrap.length > 0) {

        function cloneCarousel(effect) {
            carouselWrap.clone().appendTo('.images-gallery__wrapper').addClass('bloc-image-clone');

            carouselClone = $('.bloc-image-clone'),
                carousel = $('.images-gallery__listitems', carouselClone);
                carouselWrapper = $('.images-gallery__wrapper', carouselClone);

            if ($('.images-gallery__prev-next', carouselClone).length === 0) {

                var createPrevNext = function () {
                    carousel.parent().append('<ul class="images-gallery__prev-next">' +
                        '<li class="images-gallery__prev"><button type="button">' +
                        '<svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="178px" height="170.173px" viewBox="0 0 178 170.173" enable-background="new 0 0 178 170.173" xml:space="preserve"> <g> <defs> <rect id="SVGID_1_" x="9.676" y="5.564" width="161.09" height="160.525"/> </defs> <clipPath id="SVGID_2_"> <use xlink:href="#SVGID_1_"  overflow="visible"/> </clipPath> <path clip-path="url(#SVGID_2_)" d="M10.809,81.439c-3.893,43.993-1.94,77.422,77.743,84.455c48.573,3.517,87.47-42.221,81.623-87.972C166.307,39.227,154.627,2.273,88.552,5.79C43.847,7.556,12.749,40.977,10.809,81.439"/> </g> </svg>' +
                        '<img src="' + pathImg + 'carrousel2/carrousel2-prev.png" alt="' + l10n['slide'] + ' ' + l10n['prev'] + '"></button></li>' +
                        '<li class="images-gallery__next"><button type="button">' +
                        '<svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="178px" height="170.173px" viewBox="0 0 178 170.173" enable-background="new 0 0 178 170.173" xml:space="preserve"> <g> <defs> <rect id="SVGID_1_" x="9.676" y="5.564" width="161.09" height="160.525"/> </defs> <clipPath id="SVGID_2_"> <use xlink:href="#SVGID_1_"  overflow="visible"/> </clipPath> <path clip-path="url(#SVGID_2_)" d="M10.809,81.439c-3.893,43.993-1.94,77.422,77.743,84.455c48.573,3.517,87.47-42.221,81.623-87.972C166.307,39.227,154.627,2.273,88.552,5.79C43.847,7.556,12.749,40.977,10.809,81.439"/> </g> </svg>' +
                        '<img src="' + pathImg + 'carrousel2/carrousel2-next.png" alt="' + l10n['slide'] + ' ' + l10n['next'] + '"></button></li>' +
                        '</ul>');
                }

                if (effect === 'scrollHorz' && carouselItems > 8) {
                  //  createPrevNext();
                } else if (effect === 'carousel' && carouselItems > 1) {
                    createPrevNext();
                }

            }
        }

        function removeCarousel() {
            carouselClone = $('.bloc-image-clone');

            if (carouselClone.length > 0) {
                carouselClone.remove();
            }
        }

        function runCarousel(itemStyle, effect, itemsVisible) {
            removeCarousel();
            cloneCarousel(effect);

            carouselClone = $('.bloc-image-clone'),
                carousel = $('.images-gallery__listitems', carouselClone);

            carouselWrap.css("display", "none");
            $('.images-gallery.bloc-image-clone').css("display", "block");

            var carouselCycle = function(itemStyle, effect, itemsVisible) {
                var autoHeight = 'calc';
                if (itemsVisible === 1) {
                    autoHeight = 'calc';
                }
                carousel.cycle({
                    log:        false,
                    fx:         effect,
                    carouselVisible: itemsVisible,
                    carouselFluid:   true,
                    speed:      '600',
                    timeout:    0,
                    swipe:      true,
                    prev:       '.images-gallery__prev',
                    next:       '.images-gallery__next',
                    slides:     '.' + itemStyle,
                    autoHeight: autoHeight,
                    allowWrap:  false
                });

                function carouselWrapperCalc() {
                    var carouselWrapperHeight = 230;
                    carouselWrapper.each(function(index, item){
                        var carouselWrapperHeightNew = item.offsetHeight;
                        if (carouselWrapperHeightNew > carouselWrapperHeight) {
                            carouselWrapperHeight = carouselWrapperHeightNew;
                        }
                    })
                    return carouselWrapperHeight;
                }
                carouselWrapper.each(function(){
                    $(this).css('min-height', carouselWrapperCalc());
                })

            }
            carouselCycle(itemStyle, effect, itemsVisible);
        }

        function initCycle() {

            if (tablet.matches && smartphone.matches) {
                runCarousel("images-gallery__article", 'carousel', 1);
            } else if (!smartphone.matches && tablet.matches) {
                runCarousel("images-gallery__article", 'carousel', 2);
            } else {

            }
        }

        function reinit_cycle() {
            var destroyCarousel = function () {
                carousel.cycle('destroy');
            }

            if (tablet.matches && smartphone.matches) {
                destroyCarousel();
                reinitCycle('images-gallery__article', 'carousel', 1);
            } else if (!smartphone.matches && tablet.matches) {
                destroyCarousel();
                reinitCycle('images-gallery__article', 'carousel', 2);
            } else {
                destroyCarousel();
                removeCarousel();
                carouselWrap.css("display", "block");
            }
        }

        function reinitCycle(itemStyle, fx, itemsVisible) {
            runCarousel(itemStyle, fx, itemsVisible);
        }

        var reinitTimer;

        $(window).resize(function () {
            clearTimeout(reinitTimer);
            reinitTimer = setTimeout(reinit_cycle, 100);
        });

        $(document).ready(function () {
            initCycle();
        });

    }
}
