// Filters
function filtersState(state) {
    if (state == 'mediaDesktop' || state == 'mediaTablet') {
        $('.filters .ddm').dropDownMenu({
            disabled:    true,
            classBtn:    '',
            independent: true
        });
    } else if (state == 'mediaSmartphone') {
        $('.filters .ddm').dropDownMenu({
            disabled:    false,
            classBtn:    'button-1 button-1--no-text-in-mobile button-1--variation-1',
            independent: true
        });
    }
}
