// Menu principal
function menuStratis(state) {
    if (state == 'mediaDesktop' || state == 'mediaTablet') {
        // Gestion de l'affichage du menu déroulant à la souris et au clavier
        $('.menu-stratis > ul > li > ul').parent().bind('mouseover focusin', function () {
            $(this).find('ul').addClass('show');
            $('.menu-stratis > ul > li').removeClass('act');
            $(this).addClass('act');
        }).bind('mouseout focusout', function () {
            $(this).find('ul').removeClass('show');
            $('.menu-stratis > ul > li').removeClass('act');
        });
    } else if (state == 'mediaSmartphone') {

    }
}
