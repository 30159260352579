// Caroussel2
var carouselWrap = $('.carrousel2'),
    carousel = $('.listItems', carouselWrap),
    reinitTimer;

function carrousel2(itemVisible) {
    if (carouselWrap.length > 0) {
        var nbItem = $('.item', carousel).length,
            nbItemConfig;

        if (itemVisible) {
            nbItemConfig = itemVisible;
        } else {
            nbItemConfig = '4';
        }

        if (nbItem > nbItemConfig) {
            if ($('.prevNextCaroussel2').length == 0) {
                carousel.parent().append('<ul class="prevNextCaroussel2">' +
                    '<li class="prevCaroussel2"><button type="button" class="button-reset">' +
                    '<svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="178px" height="170.173px" viewBox="0 0 178 170.173" enable-background="new 0 0 178 170.173" xml:space="preserve"><g> <clipPath id="SVGID_2_"> <rect id="SVGID_1_" x="9.676" y="5.564" width="161.09" height="160.525"/> <use xlink:href="#SVGID_1_"  overflow="visible"/> </clipPath> <path clip-path="url(#SVGID_2_)" d="M10.809,81.439c-3.893,43.993-1.94,77.422,77.743,84.455c48.573,3.517,87.47-42.221,81.623-87.972C166.307,39.227,154.627,2.273,88.552,5.79C43.847,7.556,12.749,40.977,10.809,81.439"/> </g> </svg>' +
                    '<img src="' + pathImg + 'carrousel2/carrousel2-prev.png" alt="' + l10n['prev'] + '"></button></li>' +
                    '<li class="nextCaroussel2"><button type="button" class="button-reset">' + '<svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="178px" height="170.173px" viewBox="0 0 178 170.173" enable-background="new 0 0 178 170.173" xml:space="preserve"> <g> <clipPath id="SVGID_2_"> <rect id="SVGID_1_" x="9.676" y="5.564" width="161.09" height="160.525"/> <use xlink:href="#SVGID_1_"  overflow="visible"/> </clipPath> <path clip-path="url(#SVGID_2_)" d="M10.809,81.439c-3.893,43.993-1.94,77.422,77.743,84.455c48.573,3.517,87.47-42.221,81.623-87.972C166.307,39.227,154.627,2.273,88.552,5.79C43.847,7.556,12.749,40.977,10.809,81.439"/> </g> </svg>' +
                    '<img src="' + pathImg + 'carrousel2/carrousel2-next.png" alt="' + l10n['next'] + '"></button></li>' +
                    '</ul>');
            }

            carousel.cycle({
                log: false,
                fx: 'carousel',
                allowWrap: false,
                carouselVisible: nbItemConfig,
                carouselFluid: true,
                swipe: true,
                timeout: 0,
                prev: '.prevCaroussel2 button',
                next: '.nextCaroussel2 button',
                slides: '.item',
                autoHeight: 'calc'
            });

            $('.item', carousel).css('opacity', '1');
        }
    }
}

var destroyCarrousel = function () {
    $('.carrousel2 .listItems').cycle('destroy');
}

function reinit_cycle(nbItem) {
    setTimeout(function () {
        destroyCarrousel();
        setTimeout(function () {
            carrousel2(nbItem);
        }, 200);
    }, 200);
}

function carrousel2State(view) {
    if (view === 'mediaDesktop') {
        clearTimeout(reinitTimer);
        reinitTimer = setTimeout(reinit_cycle('4'), 200);
    }
    else if (view === 'mediaTablet') {
            clearTimeout(reinitTimer);
            reinitTimer = setTimeout(reinit_cycle('2'), 200);
        }
     else if (view === 'mediaSmartphone') {
        clearTimeout(reinitTimer);
        reinitTimer = setTimeout(reinit_cycle('1'), 200);
    }
}
