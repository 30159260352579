$(document).ready(function () {
    var mediaSmartphone = 'mediaSmartphone',
        mediaTablet = 'mediaTablet',
        mediaDesktop = 'mediaDesktop';


    var jRes = jRespond([
        {
            label: 'smartphone',
            enter: 0,
            exit: 767
        }, {
            label: 'tablet',
            enter: 768,
            exit: 1279
        }, {
            label: 'desktop',
            enter: 1280,
            exit: 10000
        }
    ]);

    jRes.addFunc([
        {
            breakpoint: 'smartphone',
            enter: function () {
                myInitFuncSmartphone();

            },
            exit: function () {
            }
        },
        {
            breakpoint: 'tablet',
            enter: function () {
                myInitFuncTablet();

            },
            exit: function () {
            }
        },
        {
            breakpoint: 'desktop',
            enter: function () {
                myInitFuncDesktop();

            },
            exit: function () {
            }
        }
    ]);

    function myInitFuncSmartphone() {
        menuStratis(mediaSmartphone); // 190_stratis_menu.js
        fancyboxGalerie(mediaSmartphone); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaSmartphone); // 240_ stratis_galerie_classique.js
        searchState(mediaSmartphone); // 290_stratis_search.js
        filtersState(mediaSmartphone); // 300_stratis_filters.js
        //carrousel1State(mediaSmartphone); // 330_stratis_carrousel1.js
        carrousel2State(mediaSmartphone); // 340_stratis_carrousel2.js
        menuMain1State(mediaSmartphone);
        initStickyMenu(mediaSmartphone);
    }

    function myInitFuncTablet() {
        menuStratis(mediaTablet); // 190_stratis_menu.js
        fancyboxGalerie(mediaTablet); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaTablet); // 240_ stratis_galerie_classique.js
        searchState(mediaTablet); // 290_stratis_search.js
        filtersState(mediaTablet); // 300_stratis_filters.js
        //carrousel1State(mediaTablet); // 330_stratis_carrousel1.js
        carrousel2State(mediaTablet); // 340_stratis_carrousel2.js
        menuMain1State(mediaTablet);
        initStickyMenu(mediaTablet);
    }

    function myInitFuncDesktop() {
        menuStratis(mediaDesktop); // 190_stratis_menu.js
        fancyboxGalerie(mediaDesktop); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaDesktop); // 240_stratis_galerie_classique.js
        searchState(mediaDesktop); // 290_stratis_search.js
        filtersState(mediaDesktop); // 300_stratis_filters.js
        //carrousel1State(mediaDesktop); // 330_stratis_carrousel1.js
        carrousel2State(mediaDesktop); // 340_stratis_carrousel2.js
        menuMain1State(mediaDesktop);
        initStickyMenu(mediaDesktop);
        $( window ).scroll(function() {
            searchState(mediaDesktop);
        });


    }

    // Generic functions
    preloadImages(); // 170_stratis_preload_images.js
    tooltipAccess(); // 200_stratis_tooltip.js
    tooltipDfn(); // 200_stratis_tooltip.js
    geocode(); // 205_stratis_geocode.js
    linkExternalNewWindow(); // 210_stratis_external_window.js
    svg4everybody (); //215_stratis_svg4everybody.js
    fancyboxClassic(); // 220_stratis_fancybox_classic.js
    fancyboxAutoHome();  // 235_stratis_fancybox_auto_home.js
    sharePage(); // 250_stratis_share_page.js
    eventDdm(); // 260_stratis_event-ddm.js
    contentDdm(); // 270_stratis_content-ddm.js
    manageVideo(); // 280_stratis_video.js
    enableDatePicker(); // 310_stratis_datetimepicker.js
    enableTimePicker(); // 310_stratis_datetimepicker.js
    formValidation(); // 320_stratis_form_validation.js
    infiniteScroll(); // 350_stratis_infinite_scroll.js
    showTotal(); // 350_stratis_infinite_scroll.js
    catmenu(); // 355_stratis_catmenu.js
    faqDdm(); // 370_stratis_faq.js
    addContentInTitle(); // 380_stratis_add_in_title.js
    generatePie(); // 390_stratis_piechart.js
    carouselImageState(); // 345_stratis_carrousel-image.js

    const $popupVideoPlayer = $("#popup-video-player");

    $(".fancybox--video").fancybox({
        maxWidth: 800,
        maxHeight: 450,
        fitToView: true,
        width: '100%',
        height: 'auto',
        autoSize: false,
        aspectRatio: true,
        showCloseButton: true,
        padding: 5,
        wrapCSS: 'fancybox-video-wrapper',
        tpl:         {
            closeBtn: '<button type="button" title="' + l10n['closeModalWindow'] + '" class="fancybox-item fancybox-close" ><span class="ghost">' + l10n['close'] + '</span></button>',
        },
        beforeShow: function() {
            const videoSrc = $(this.element).data('video-src');
            $popupVideoPlayer.attr('src', videoSrc);
            $popupVideoPlayer.get(0).load();
        },
        afterClose: function() {
            $popupVideoPlayer.attr('src', '');
            $popupVideoPlayer.get(0).pause();
        }
    });

    $('.fancybox--accessibility').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        $.fancybox.open({
            href: $(this).data('fancybox-href'),
            maxWidth: 800,
            fitToView: true,
            autoSize: true,
            showCloseButton: true,
            wrapCSS: 'fancybox-accessibility-wrapper',
            closeBtn: true,
            padding: 0,
            tpl:         {
                closeBtn: '<button type="button" title="' + l10n['closeModalWindow'] + '" class="fancybox-item fancybox-close" ><span class="ghost">' + l10n['close'] + '</span></button>',
            },
            beforeShow: function() {
                this.width = $(window).width() * 0.8;
                this.height = $(window).height() * 0.8;
            },
        });
    });

    // $('.link-external').on('click', function(e) {
    //     if (!$(e.target).is('.fancybox--accessibility')) {
    //         window.location.href = this.href;
    //     }
    // });
});

accessTooltipState(); // 400_stratis_access-tooltip.js

