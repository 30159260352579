// Tooltip sur image
function tooltipOn(mytooltip) {
    if ($(mytooltip).parents('.galleriffic').length > 0 && $(mytooltip).find('img').attr('title') && $(mytooltip).find('img').attr('title') != '') {
        var tooltipText = "";
        tooltipText = $(mytooltip).find('img:not(".imgHover")').attr('title');
    }
    else if ($(mytooltip).attr('title') && $(mytooltip).attr('title') != '') {
        var tooltipText = '';
        var tooltipText = $(mytooltip).attr('title');
    } else {
        var tooltipText = '';
        var tooltipText = $(mytooltip).find('img:not(".imgHover")').attr("alt");
    }
    if (tooltipText) {
        $(mytooltip).find('img:not(".imgHover")').before('<span class="tooltip">' + tooltipText + '<span class="cornerTooltip"></span></span>');
        $(mytooltip).find(".tooltip").hide();
        $(mytooltip).find(".tooltip").fadeIn(50);
        /*if($(mytooltip).parents('.galleriffic').length > 0 && $(mytooltip).find('img').attr('title') != ''){
         $(mytooltip).find('img:not(".imgHover")').attr('title','');
         }
         else if($(mytooltip).attr('title','')){
         $(mytooltip).attr('title','');
         }*/
    }
}
function tooltipOff(mytooltip) {
    var tooltipText = $(mytooltip).text();
    $('.tooltip').fadeOut(50, function () {
        $(this).remove();
        /*if($(mytooltip).parents('.galleriffic').length > 0 && $(mytooltip).find('img').attr('title') != ''){
         $(mytooltip).find('img').attr('title',tooltipText);
         }
         else if($(mytooltip).attr('title',tooltipText)){
         $(mytooltip).attr('title','');
         }*/
    });
}

// Tooltip sur image
function tooltipAccess() {
    $(".tooltipAccess")
        .hover(function () {
            tooltipOn(this);
        }, function () {
            tooltipOff(this);
        })
        .focusin(function () {
            tooltipOn(this);
        })
        .focusout(function () {
            tooltipOff(this);
        });
}

// Tooltip sur les definitions
function tooltipDfnOn(mytooltip) {
    var definition = $(mytooltip).find("i.ghost").text();
    if (definition) {
        $(mytooltip).find("i.ghost").removeClass('ghost');
    }
}
function tooltipDfnOff(mytooltip) {
    $('.tooltipDfnBulle').addClass('ghost');
}

// Tooltip sur les definitions
function tooltipDfn() {
    $(".tooltipDfn")
        .attr('tabindex', '0')
        .hover(function () {
            tooltipDfnOn(this);
        }, function () {
            tooltipDfnOff(this);
        })
        .focusin(function () {
            tooltipDfnOn(this);
        })
        .focusout(function () {
            tooltipDfnOff(this);
        });
}
